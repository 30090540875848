import React from 'react'

function DoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      fill="none"
      viewBox="0 0 12 8"
    >
      <path
        fill="#FAFAFA"
        d="M4.15 5.966l-2.437-2.37L.667 4.615 4.15 8l7.184-6.983L10.287 0 4.15 5.966z"
      />
    </svg>
  )
}

export default DoneIcon
