import { findAta } from '@cardinal/common'
import { AccountLayout, AccountInfo } from '@solana/spl-token'
import { useQuery } from 'react-query'
import connection from 'utils/connection'
import useRewardDistributorData from './useRewardDistributor'

export const useRewardDistributorTokenAccount = (stakePoolId: string) => {
  const rewardDistibutorData = useRewardDistributorData(stakePoolId)
  return useQuery<AccountInfo>(
    [
      'useRewardDistributorTokenAccount',
      rewardDistibutorData?.data?.pubkey?.toString(),
    ],
    async () => {
      if (!rewardDistibutorData.data) {
        return {}
      }
      const rewardDistributorTokenAccount = await findAta(
        rewardDistibutorData.data.parsed.rewardMint,
        rewardDistibutorData.data.pubkey,
        true,
      )
      const info = await connection.getAccountInfo(rewardDistributorTokenAccount)
      const rawAccount = AccountLayout.decode(Buffer.from(info!.data))

      return rawAccount
    },
    { enabled: !!rewardDistibutorData.data },
  )
}

export default useRewardDistributorTokenAccount
