import { Connection } from '@solana/web3.js'

const connection = new Connection('https://api.devnet.solana.com')

async function getOwnerAta(ownerAddress, mintAddress) {
  try {
    const response = await connection.getTokenAccountsByOwner(ownerAddress, {
      // mint: new PublicKey(mintAddress), // should be same shit
      mint: mintAddress,
    })
    if (!response.value) {
      return {
        success: false,
        reason: 'Cannot check if ata exist',
      }
    }

    if (response.value.length > 0) {
      return {
        success: true,
        address: response.value[0].pubkey.toBase58(),
      }
    }
    // doesnt have ata address
    return {
      success: true,
      address: '',
    }
  } catch (e) {
    return {
      success: false,
      reason: e,
    }
  }
}

export default getOwnerAta
