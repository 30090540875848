import React from 'react'

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="48"
    fill="none"
    viewBox="0 0 49 48"
  >
    <g filter="url(#filter0_d_485_2731)">
      <g filter="url(#filter1_i_485_2731)">
        <ellipse
          cx="24.168"
          cy="24.334"
          fill="#E04B4C"
          rx="18.38"
          ry="18.38"
        />
      </g>
      <mask
        id="mask0_485_2731"
        style={{ maskType: 'alpha' }}
        width="38"
        height="38"
        x="5"
        y="5"
        maskUnits="userSpaceOnUse"
      >
        <ellipse
          cx="24.168"
          cy="24"
          fill="#E04B4C"
          rx="18.38"
          ry="18.046"
          transform="rotate(-24.236 24.168 24)"
        />
      </mask>
      <g mask="url(#mask0_485_2731)">
        <rect
          width="40.675"
          height="40.124"
          fill="url(#paint0_linear_485_2731)"
          rx="20"
          transform="matrix(.9357 .35282 -.36428 .93129 4.364 -18.981)"
        />
      </g>
      <ellipse
        cx="15.471"
        cy="9.307"
        fill="#fff"
        rx="1.671"
        ry="0.498"
        transform="rotate(-34.01 15.471 9.307)"
      />
      <g filter="url(#filter2_di_485_2731)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M31.033 17.243a2.676 2.676 0 00-3.784-.079l-3.416 3.278-3.415-3.278a2.676 2.676 0 00-3.706 3.863l3.256 3.124-3.417 3.279a2.676 2.676 0 103.706 3.862l3.576-3.432 3.577 3.432a2.677 2.677 0 003.706-3.862l-3.417-3.279 3.256-3.124a2.676 2.676 0 00.078-3.784z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_485_2731"
        width="54.335"
        height="54"
        x="-3"
        y="-1"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix values="0 0 0 0 0.232731 0 0 0 0 0.177645 0 0 0 0 0.160249 0 0 0 0.45 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_485_2731"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_485_2731"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_485_2731"
        width="36.76"
        height="40.76"
        x="5.788"
        y="5.954"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.970264 0 0 0 0 0.682487 0 0 0 0 0.344662 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_485_2731" />
      </filter>
      <filter
        id="filter2_di_485_2731"
        width="20.212"
        height="20.618"
        x="13.727"
        y="16.419"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix values="0 0 0 0 0.778597 0 0 0 0 0.133834 0 0 0 0 0.138161 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_485_2731"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_485_2731"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.941176 0 0 0 0 0.564706 0 0 0 0 0.564706 0 0 0 1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_485_2731" />
      </filter>
      <linearGradient
        id="paint0_linear_485_2731"
        x1="40.408"
        x2="27.983"
        y1="36.585"
        y2="26.094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8C81" />
        <stop offset="1" stopColor="#EA6A5D" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default CloseIcon
