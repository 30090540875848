import React from 'react'

import { translate } from '../../localization'

interface SwitchTabsProps {
  active: string,
  options: Array<string>,
  // @todo: add type later
  toggleSwitch: any,
}

const SwitchTabs = ({ active, options, toggleSwitch }: SwitchTabsProps) => (
  <div className="custom-switch-container">
    {options.map((option, index) => (
      <div
        key={option}
        className={active === option ? 'active option-container' : 'option-container'}
        style={{ width: options.length === 3 ? '33%' : '49%' }}
        onClick={() => toggleSwitch(option)}
      >
        <div className="option-text-container">
          <p>{translate(option)}</p>
        </div>
        {(index + 1 !== options.length) && ((option !== active) && <div className="divider" />)}
      </div>
    ))}
  </div>
)

export default SwitchTabs
