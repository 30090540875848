import { useQuery } from 'react-query'
import { AccountData } from '@cardinal/common'
import { RewardEntryData } from '@cardinal/staking/dist/cjs/programs/rewardDistributor'
import { getRewardEntries } from '@cardinal/staking/dist/cjs/programs/rewardDistributor/accounts'
import { findRewardEntryId } from '@cardinal/staking/dist/cjs/programs/rewardDistributor/pda'
import connection from 'utils/connection'
import useRewardDistributor from './useRewardDistributor'
import useStakedToken from './useStakedToken'

const useRewardEntries = (stakePoolId: string) => {
  const { data: rewardDistibutorData } = useRewardDistributor(stakePoolId)
  const { data: stakedTokenDatas } = useStakedToken()
  return useQuery<AccountData<RewardEntryData>[] | undefined>(
    [
      'useRewardEntries',
      rewardDistibutorData?.pubkey?.toString(),
      stakedTokenDatas,
    ],
    async () => {
      const rewardDistibutorId = rewardDistibutorData?.pubkey
      if (!rewardDistibutorData || !stakedTokenDatas || !rewardDistibutorId) {
        return []
      }
      const stakeEntryIds = stakedTokenDatas.map((entry) => entry.pubkey)

      const rewardEntryIds = await Promise.all(
        stakeEntryIds.map(
          async (stakeEntryId) => (await findRewardEntryId(rewardDistibutorId, stakeEntryId))[0],
        ),
      )

      return (
        await getRewardEntries(connection, rewardEntryIds)
      ).filter((rewardEntry) => rewardEntry.parsed)
    },
  )
}

export default useRewardEntries
