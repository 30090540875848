import React from 'react'

function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="53"
      fill="none"
      viewBox="0 0 40 53"
    >
      <g clipPath="url(#clip0_455_3459)">
        <path
          fill="#AAA"
          d="M32.348 41.094c1 0 1.818-.817 1.818-1.817V20.844c0-6.501-.732-10.851-2.374-14.105C29.536 2.267 25.569 0 20 0c-5.568 0-9.536 2.267-11.792 6.739-1.642 3.254-2.374 7.603-2.374 14.105v18.433c0 1 .818 1.817 1.817 1.817h1.304c1 0 1.817-.817 1.817-1.817V20.844c0-3.53.192-8.604 1.845-11.88C14.02 6.18 16.297 4.937 20 4.937c3.7 0 5.978 1.242 7.383 4.025 1.652 3.276 1.844 8.35 1.844 11.881v18.433c0 1 .817 1.817 1.817 1.817h1.304z"
        />
        <path
          fill="#FFAB15"
          d="M40 50.192a2.477 2.477 0 01-2.47 2.47H2.47A2.477 2.477 0 010 50.191V22.908a2.476 2.476 0 012.47-2.469H37.53c1.358 0 2.47 1.11 2.47 2.469v27.284H40z"
        />
        <path
          fill="#FFC50B"
          d="M2.47 20.439c-1.09 0-1.976.886-1.976 1.975v27.284c0 1.09.886 1.975 1.975 1.975h35.062c1.09 0 1.976-.886 1.976-1.975V22.414a1.978 1.978 0 00-1.976-1.975H2.47z"
        />
        <path
          fill="#FFD91F"
          d="M27.918 20.439H2.469c-1.09 0-1.975.886-1.975 1.975v25.45l27.424-27.425z"
        />
        <path
          fill="#FFAB15"
          d="M25.185 33.494a5.185 5.185 0 00-10.37 0c0 2.89 2.19 4.103 2.19 4.103.191.192.306.568.255.835l-1.118 5.874c-.051.267.13.484.401.484h6.914a.394.394 0 00.401-.484l-1.118-5.874a1.05 1.05 0 01.255-.835s2.19-1.153 2.19-4.103z"
        />
        <path
          fill="#B76732"
          d="M15.309 33.989c0 2.213 1.952 3.674 1.952 3.674.19.194.411.393.493.443.081.048.107.306.056.572l-1.072 5.627c-.051.267.13.485.401.485h5.72a.394.394 0 00.402-.485L22.19 38.68c-.051-.267-.026-.525.056-.573.082-.05.303-.249.493-.443 0 0 1.952-1.34 1.952-3.674A4.697 4.697 0 0020 29.298a4.696 4.696 0 00-4.691 4.69z"
        />
        <path
          fill="#FFEA94"
          d="M11.076 24c.446 1.22-.937 2.302-1.65 3.103-1.07 1.2-1.732 1.881-2.668 2.901-.663.724-1.766 1.973-2.893 1.634-1.274-.385-1.33-2.017-1.335-3.098-.009-1.35-.29-3.523.568-4.672.812-1.086 2.228-.89 3.455-.928 1.261-.039 3.197-.154 4.05.456.247.179.395.388.473.604z"
        />
      </g>
      <defs>
        <clipPath id="clip0_455_3459">
          <path fill="#fff" d="M0 0H40V52.661H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LockIcon
