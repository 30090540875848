import React, { useState } from 'react'

export interface IConfettiContext {
  isRun: boolean;
  setIsRun: (isRun: boolean) => void;
}

const defaultConfetti = {
  isRun: false,
  setIsRun: () => {},
}

export const ConfettiContext = React.createContext<IConfettiContext>(defaultConfetti)

export function ConfettiProvider({ children }) {
  const [isRun, setIsRun] = useState(false)

  return (
    <ConfettiContext.Provider value={{ isRun, setIsRun }}>
      {children}
    </ConfettiContext.Provider>
  )
}
