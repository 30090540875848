/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import Joyride, { STATUS } from 'react-joyride'

import { translate } from '../localization'

interface OnBoardingContainerProps {
  isOnboardingRun: boolean;
}

const spotLightBuildingStyle = {
  spotlight: {
    clipPath: 'polygon(50% 10%, 100% 46%, 100% 63%, 50% 100%, 0 63%, 0 48%)',
    marginTop: '-15px',
  },
}

const OnBoardingContainer = ({ isOnboardingRun }: OnBoardingContainerProps) => {
  const steps = [
    { target: '.nav-item .wallet-adapter-button', content: <div className="onboarding-container"><p>{translate('onboarding1')}</p></div>, disableBeacon: true },
    {
      target: 'body',
      content: <p>
        You are breathtaking! ❤️
        <br />
        {translate('onboarding2')}
      </p>,
      styles: { options: { width: '600px' } },
    },
    { target: '.balances-sheet-container', content: <p>{translate('onboarding3')}</p>, position: 'top' },
    {
      target: '.timber-camp',
      content: <p>{translate('onboarding4')}</p>,
      styles: spotLightBuildingStyle,
    },
    {
      target: '.stone-quary',
      content: <p>{translate('onboarding5')}</p>,
      styles: spotLightBuildingStyle,
    },
    {
      target: '.iron-mine',
      content: <p>{translate('onboarding6')}</p>,
      styles: spotLightBuildingStyle,
    },
    {
      target: '.wheat-farm',
      content: <p>{translate('onboarding7')}</p>,
      styles: spotLightBuildingStyle,
    },
    // need to force user to open that modal and check somehow
    { target: '.workers-button', content: <p>{translate('onboarding8')}</p> },
    { target: '.marketplace-button', content: <p>{translate('onboarding9')}</p> },
    { target: '.quests-button', content: <p>{translate('onboarding10')}</p> },
    { target: 'body', content: <p>{translate('onboarding11')}</p> },
    {
      target: '.timber-camp',
      content: <p>{translate('onboarding12')}</p>,
      styles: { options: { width: '650px' }, ...spotLightBuildingStyle },
    },
  ]

  return (
    <Joyride
      scrollToFirstStep
      hideCloseButton
      continuous
      showProgress
      showSkipButton
      steps={steps}
      run={isOnboardingRun}
      styles={{
        spotlight: {
          width: '80vw !important',
          borderRadius: 8,
          background: 'rgba(255, 255, 255, 0.3)',
        },
        tooltip: {
          borderRadius: 8,
        },
        buttonBack: {
          color: '#ccc',
        },
        buttonNext: {
          background: '#8A61FA',
        },
        options: {
          arrowColor: '#1a1c22',
          backgroundColor: '#1a1c22',
          primaryColor: '#000',
          textColor: 'white',
          zIndex: 10,
        },
      }}
      callback={async (state: any) => {
        // @todo: need to check wallet connect and some clicks
        if ([STATUS.SKIPPED, STATUS.FINISHED].includes(state.status)) {
          console.log('User skipped or finished onboarding, update storage')
          await localStorage.setItem('onBoarding', 'yes')
        }
      }}
    />
  )
}

export default OnBoardingContainer
