import BN from 'bn.js'
import { BigNumber } from 'bignumber.js'
// import type { MintInfo } from '@solana/spl-token'

export default function getMintDecimalAmountFromNatural(
  // mint: MintInfo,
  naturalAmount: BN,
) {
  return new BigNumber(naturalAmount.toString()).shiftedBy(-9).toFormat(2).toLocaleString()
}

// export function formatMintNaturalAmountAsDecimal(
//   mint: MintInfo,
//   naturalAmount: BN,
//   decimalPlaces?: number,
// ) {
//   return getMintDecimalAmountFromNatural(mint, naturalAmount).toFormat(
//     decimalPlaces,
//   )
// }

// formatMintNaturalAmountAsDecimal(
//   rewardMintInfo.data.mintInfo,
//   rewards.data?.rewardMap[
//     tk.stakeEntry.pubkey.toString()
//   ]?.claimableRewards || new BN(0),
//   // max of 5 decimals
//   Math.min(
//     rewardMintInfo.data
//       .mintInfo.decimals,
//     5
//   )
// ).toLocaleString()
