import CONFIG from '../config.json'

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value) || ''
}

export function now() {
  return Math.round(Date.now() / 1000)
}

export function formatText(text: string, length: number): string {
  if (!text) return ''
  if (text.length > length) {
    return `${text.slice(0, length)}...`
  }
  return text
}

export const getBuildingCost = (buildingType: string, level: number) => CONFIG.production[`${buildingType}-${level}`]?.cost || 0
